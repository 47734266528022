<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Maintenance : {{MaintenanceCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <CInput id="MaintenanceId" v-model="MaintenanceId" class="font-weight-bold" hidden/>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Kode Maintenance</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="MaintenanceCode" v-model="MaintenanceCode" class="font-weight-bold" :disabled="this.DisabledInput" />
                            <label id="errorMaintenanceCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Tanggal Maintenance</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="MaintenanceDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                                v-bind:disabled="this.DisabledInput"
                            ></date-picker>
                            <CInput id="MaintenanceDate2" v-model="MaintenanceDate2" class="font-weight-bold" hidden/>
                            <label id="errorMaintenanceDate2" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Status" class="pb-3" :options="StatusData" v-model="Status" />
                            <label id="errorStatus" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Machine" class="pb-3" :options="MachineData" v-model="Machine" :disabled="DisabledInput" />
                            <label id="errorMachine" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Gudang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Storage" class="pb-3" :options="StorageData" v-model="Storage" :disabled="this.DisabledInput" @input="onChangeStorage()" />
                            <label id="errorStorage" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Downtime (Menit)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Downtime" v-model="Downtime" class="font-weight-bold" type="number" min="0" :disabled="this.DisabledInput" 
                                oninput="this.value = Math.round(this.value);" />
                            <label id="errorDowntime" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow class="pb-2">
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold" >Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="Note" class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold" >Catatan Tambahan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="NoteAdditional" class="form-control" rows="2" v-model="NoteAdditional"></textarea>
                            <label id="errorNoteAdditional" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold" >Catatan Persetujuan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="NoteApproval" class="form-control" rows="2" v-model="NoteApproval"></textarea>
                            <label id="errorNoteApproval" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold" >Downtime</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="checkbox" v-model="HasDowntime" :disabled="this.DisabledInput" @change="onCheckDowntime()" /> <label>Memiliki Downtime</label>
                            <label id="errorHasDownTime" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">    
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item Maintenance</h4></label>
                            <div class="row float-right pr-3">
                                <button class="btn btn-primary" @click="addMaintenanceItemClick()" :hidden="this.HiddenAction" >
                                    <i class="fa fa-plus"></i> Add Item
                                </button>
                            </div>
                            <hr>
                            <datasource ref="MaintenanceItemDataSource" :data="this.MaintenanceItemGridData" :page-size="10" :schema-model-fields="this.MaintenanceItemGridDataSchemaModel"/>

                            <kendo-grid ref="gridMaintenanceItem"
                                :data-source-ref="'MaintenanceItemDataSource'"
                                :editable="true"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                            >

                                <kendo-grid-column  :field="'maintenance_item_usage_id'"
                                                    :title="'Id Item Maintenance'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'maintenance_id'"
                                                    :title="'Id Maintenance'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'item_id'"
                                                    :title="'Id Item'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'base_uom'"
                                                    :title="'Satuan Dasar'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :title="'Action'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :command="['destroy']"
                                                    :width="200"
                                                    :hidden="this.HiddenAction"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                        
                        <maintenance-type-item-form ref="MaintenanceItemDataForm" :saveMaintenanceItemGrid="saveMaintenanceItemGrid"/>
                    </CRow>
                    
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/maintenance')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';

import maintenanceItemForm from '../Component/ItemListForm.vue';
import maintenanceService from '../Script/MaintenanceServices.js';

export default {
    name: 'MaintenanceForm',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'maintenance-type-item-form' : maintenanceItemForm,
    },
    data() {
        return {
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,

            ModalTitle: '',

            MaintenanceId:'',
            MaintenanceCode:'',
            MaintenanceDate:'',
            MaintenanceDate2: '',

            Status:'',
            StatusData:[],
            Machine:'',
            MachineData:[],
            Storage:'',
            StorageData:[],

            Downtime: 0,
            Note: '',
            NoteAdditional: '',
            NoteApproval: '',

            HasDowntime: false,

            MaintenanceItemGridData: [],
            MaintenanceItemGridDataSchemaModel: {
                maintenance_item_usage_id: { type: "string", editable: false},
                maintenance_id: { type: "string", editable: false},
                item_id: { type: "string", editable: false},
                product_code: { type: "string", editable: false},
                item_name: { type: "string", editable: false},
                base_uom: { type: "string", editable: false},
                quantity: { type: "number", editable: false},
            },
            
            SaveType: '',
            DisabledInput: false,
            HiddenAction: false,
        }
    },
    async mounted() {
        this.$loading(true);
        $(".form-error").css("display", "none");

        if (this.FormType == 'Add') {
            this.MaintenanceId = null;
            this.MaintenanceCode = 'Auto';
                        
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.MaintenanceDate = dateNow;
            
            this.MachineData = await maintenanceService.GetMachineQuery();
            this.Machine = '';
                        
            this.StorageData = await maintenanceService.GetStorageQuery();
            this.Storage = '';

            this.StatusData = [
                { value:'New', label:'New' }
            ];
            this.Status = this.StatusData.find(c => c.value == 'New');
            
            this.Downtime = 0;
            this.Note = '';
            this.NoteAdditional = '';
            this.NoteApproval = '';
            this.HasDowntime = false;

            this.MaintenanceItemGridData = [];
            
            this.SaveType = 'Add';
            this.HiddenAction = false;
            
            document.getElementById('Downtime').disabled = true;
            $(".save-button").show();
        }
        else {
            this.FormType = 'Edit';

            var data = await maintenanceService.GetMaintenance(this.Id);
            
            this.MaintenanceId = data.maintenance_id;
            this.MaintenanceCode = data.maintenance_code;
            
            this.MaintenanceDate = moment(String(data.maintenance_date)).format("YYYY-MM-DD");
            
            this.MachineData = await maintenanceService.GetMachineQuery();
            this.Machine = this.MachineData.find(c => c.value == data.machine_id);
                        
            this.StorageData = await maintenanceService.GetStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == data.storage_id);

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_maintenance');
            this.Status = this.StatusData.find(c => c.value == data.status);
            
            this.Downtime = data.downtime;
            this.Note = data.notes;
            this.NoteAdditional = data.additional_notes;
            this.NoteApproval = data.approval_notes;
            this.HasDowntime = data.has_downtime;
            if (this.HasDowntime) {
                document.getElementById('Downtime').disabled = false;
            }
            else {
                document.getElementById('Downtime').disabled = true;
            }
            
            this.MaintenanceItemGridData = [];
            if (data.maintenance_item_usage != null) {
                this.MaintenanceItemGridData = this.$globalfunc.objectToArrayConverter(data.maintenance_item_usage, "Maintenance-MaintenanceItemGet");
            }

            this.SaveType = 'Edit';

            if (data.status != 'New') {
                this.HiddenAction = true;
                this.DisabledInput = true;
            }
            else {
                this.HiddenAction = false;
                this.DisabledInput = false;
            }

            if(this.View == 'true'){
                this.FormType = 'View';
                $(".save-button").hide();
            }
            else {
                $(".save-button").show();
            }
        }

        this.$loading(false);
    },
    methods: {
        onCheckDowntime() {
            if (this.HasDowntime) {
                document.getElementById('Downtime').disabled = false;
            }
            else {
                document.getElementById('Downtime').disabled = true;
            }
        },
        onChangeStorage() {
            this.MaintenanceItemGridData = [];
        },
        addMaintenanceItemClick(){
            if (this.Storage === undefined || this.Storage.value === undefined) {
                this.$swal("Info", "Harap untuk memilih Gudang terlebih dahulu", "info");
            }
            else {
                this.$refs.MaintenanceItemDataForm.addClick(this.Storage.value);
            }
        },
        saveMaintenanceItemGrid(data){
            var gridData = this.$refs.gridMaintenanceItem.kendoWidget().dataSource._data;
            var itemData = this.$globalfunc.objectToArrayConverter(gridData, 'Maintenance-MaintenanceItemGet');
            var newData = maintenanceService.gridDataConverter(data, this.MaintenanceId);

            var duplicate = false;
            if(data.length > 0){
                duplicate = maintenanceService.duplicateMaterialChecker(itemData, newData);
            }

            if(duplicate){
                this.$swal("Error", "Item sudah ada", "error");
            }
            else{
                itemData = newData.concat(itemData);
                this.MaintenanceItemGridData = itemData;
            }
        },
        
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if (this.MaintenanceDate == null || this.MaintenanceDate == 'Invalid date') {
                this.errorShow('errorMaintenanceDate2');
            }
            if (this.Status === undefined || this.Status.value === undefined) {
                this.errorShow('errorStatus');
            }
            if (this.Machine === undefined || this.Machine.value === undefined) {
                this.errorShow('errorMachine');
            }
            if (this.Storage === undefined || this.Storage.value === undefined) {
                this.errorShow('errorStorage');
            }

            if (this.HasDowntime) {
                if (this.Downtime == 0 || this.Downtime == null) {
                    this.errorShow('errorDowntime');
                }
            }

            var MaintenanceItemGridData = this.$refs.gridMaintenanceItem.kendoWidget().dataSource._data;
            if (MaintenanceItemGridData.length == 0) {
                this.Error++;
                this.$swal("Error", "Data Item Maintenance harus di isi", "error");
            }
            else {                
                for(var i=0; i<MaintenanceItemGridData.length; i++){
                    if (MaintenanceItemGridData[i].quantity == 0) {
                        this.$swal("Error", "Field Quantity pada List Item Maintenance harus di isi", "error");
                        this.Error++;
                        break;
                    }
                }
            }
        },
        async saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                var MaintenanceItemGridData = this.$refs.gridMaintenanceItem.kendoWidget().dataSource._data;

                var MaintenanceItemArray = this.$globalfunc.objectToArrayConverter(MaintenanceItemGridData, "Maintenance-MaintenanceItemSave")

                const maintenanceData = {
                    maintenance_id : this.MaintenanceId,
                    maintenance_code : this.MaintenanceCode,
                    maintenance_date : this.MaintenanceDate,
                    status : this.Status.value,
                    machine_id : parseInt(this.Machine.value),
                    storage_id : this.Storage.value,
                    production_id : null,
                    downtime : this.Downtime,
                    has_downtime : this.HasDowntime,
                    notes : this.Note,
                    additional_notes : this.NoteAdditional,
                    approval_notes : this.NoteApproval,
                    detail : MaintenanceItemArray
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : maintenanceData
                    }

                    maintenanceService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/maintenance' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.MaintenanceId),
                        data : maintenanceData
                    }
                    
                    maintenanceService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success").then(
                            this.$router.push({ path: '/maintenance' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>