<template>
    <div>
        <div class="modal fade" id="MaintenanceStatusModal" tabindex="-1" aria-labelledby="MaintenanceStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="MaintenanceStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{MaintenanceCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

import maintenanceService from '../Script/MaintenanceServices.js';

export default {
    name: 'MaintenanceStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            MaintenanceCode : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(dataId, dataCode, dataStatus){
            this.ModalTitle = 'Edit Status Maintenance: ';
            this.Id = dataId;
            this.MaintenanceCode = dataCode;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_maintenance');
            this.Status = dataStatus;
            
            window.$('#MaintenanceStatusModal').modal('show');
        },
        async saveClick(){
            this.$loading(true);

            var data = await maintenanceService.GetMaintenance(this.Id);                    
            var MaintenanceItemArray = this.$globalfunc.objectToArrayConverter(data.maintenance_item_usage, "Maintenance-MaintenanceItemSave");

            const maintenanceData = {
                maintenance_id : data.maintenance_id,
                maintenance_code : data.maintenance_code,
                maintenance_date : moment(data.maintenance_date).format("YYYY-MM-DD"),
                status : this.Status,
                machine_id : parseInt(data.machine_id),
                storage_id : data.storage_id,
                production_id : data.production_id,
                downtime : data.downtime,
                has_downtime : data.has_downtime,
                notes : data.notes,
                additional_notes : data.additional_notes,
                approval_notes : data.approval_notes,
                detail : MaintenanceItemArray
            }

            const variables = {
                id: parseInt(this.Id),
                data : maintenanceData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    maintenanceService.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#MaintenanceStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
            this.$loading(false);
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>