<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Maintenance</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <maintenance-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" />
                <maintenance-status-form ref="MaintenanceStatusModal" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import moment from 'moment';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

import maintenanceGrid from '../Maintenance/Grid/MaintenanceGrid.vue';
import maintenanceStatusForm from '../Maintenance/Component/MaintenanceStatusForm.vue';
import maintenanceService from '../Maintenance/Script/MaintenanceServices.js';

export default {
    name: 'Machine',
    components: {
        'maintenance-grid': maintenanceGrid,
        'maintenance-status-form': maintenanceStatusForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Maintenance');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$router.push({ name: 'Maintenance Form', params: {  formtype:'Add', id:' ', view:'new' } });
        },
        editClick(id, view) {
            const routeData = this.$router.resolve({ name: 'Maintenance Form', params: {  formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id) {
            var data = await maintenanceService.GetMaintenance(id);                    
            var MaintenanceItemArray = this.$globalfunc.objectToArrayConverter(data.maintenance_item_usage, "Maintenance-MaintenanceItemSave");

            const maintenanceData = {
                maintenance_id : data.maintenance_id,
                maintenance_code : data.maintenance_code,
                maintenance_date : moment(data.maintenance_date).format("YYYY-MM-DD"),
                status : "Rejected",
                machine_id : parseInt(data.machine_id),
                storage_id : data.storage_id,
                production_id : data.production_id,
                downtime : data.downtime,
                has_downtime : data.has_downtime,
                notes : data.notes,
                additional_notes : data.additional_notes,
                approval_notes : data.approval_notes,
                detail : MaintenanceItemArray
            }

            const variables = {
                id: parseInt(id),
                data : maintenanceData
            }
            
            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    maintenanceService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        this.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
        },
        statusClick(data){
            this.$refs.MaintenanceStatusModal.statusClick(data.maintenance_id, data.maintenance_code, data.status);
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnOngoing").on('click', function() {
                    vue.$refs.grid.changeStatus('Ongoing');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnRejected").on('click', function() {
                    vue.$refs.grid.changeStatus('Rejected');
                });
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>