import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class MaintenanceServices {
    readQuery(){
        var query = `
            query ($paging:ServerPaging, $status:String) {
                GetMaintenance (Paging:$paging, status:$status) {
                    maintenance {
                        created_at
                        last_update
                        maintenance_id
                        maintenance_code
                        maintenance_date
                        status
                        machine_id
                        machine_code
                        machine_name
                        storage_id
                        production_id
                        downtime
                        has_downtime
                        created_by
                        approved_by
                        notes
                        additional_notes
                        approval_notes
                    }
                    total
                }
            }
        `;
        return query;
    }

    async GetMaintenance(id) {
        const variables = {
            id : id,
        }
        var query = gql`
            query ($id:Int) {
                GetMaintenance (maintenanceID:$id) {
                    maintenance {
                        created_at
                        last_update
                        maintenance_id
                        maintenance_code
                        maintenance_date
                        status
                        machine_id
                        machine_code
                        machine_name
                        storage_id
                        production_id
                        downtime
                        has_downtime
                        created_by
                        approved_by
                        notes
                        additional_notes
                        approval_notes
                        maintenance_item_usage {
                            created_at
                            last_update
                            maintenance_item_usage_id
                            maintenance_id
                            item_id
                            product_code
                            item_name
                            quantity
                            base_uom
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetMaintenance.maintenance[0];
    }

    async GetStorageQuery() {
        var query = gql`
            query {
                GetStorage {
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var label = result.data.GetStorage[i].storage_id + ' - ' + result.data.GetStorage[i].storage_name + ''
            var str = {
                value:result.data.GetStorage[i].storage_id,
                label:label
            }
            storageData.push(str);
        }
        return storageData
    }
    
    async GetMachineQuery() {
        var query = gql`
            query {
                GetMachine {
                    machine {
                        machine_id
                        machine_code
                        machine_name
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var machineData = [];
        for (let i = 0; i < result.data.GetMachine.total; i++) {
            var label = result.data.GetMachine.machine[i].machine_code + ' - ' + result.data.GetMachine.machine[i].machine_name + ''
            var str = {
                value:result.data.GetMachine.machine[i].machine_id,
                label:label
            }
            machineData.push(str);
        }
        return machineData
    }
    
    GetMaintenanceItemDataQuery() {        
        var query = `
            query ($id:String, $paging:ServerPaging) {
                GetItemStockSalesV2 (StorageId:$id, Paging:$paging) {
                    item_stock_sales {
                        item_id
                        product_code
                        item_name
                        base_uom
                        stock
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    selectedMaintenanceItemConverter(data) {
        var newData = data.split(";");
        let array = [];
        for (let i = 0; i < newData.length; i++) {
            var splitData = newData[i].split("|")
            var str = {
                maintenance_item_usage_id: null,
                item_id: splitData[0],
                product_code: splitData[1],
                item_name: splitData[2],
                base_uom: splitData[3],
                quantity: 0
            }            
            array.push(str);
        }

        return array;
    }

    gridDataConverter(data, MaintenanceId){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                maintenance_item_usage_id: data[i].maintenance_item_usage_id,
                maintenance_id: MaintenanceId,
                item_id: data[i].item_id,
                product_code: data[i].product_code,
                item_name: data[i].item_name,
                base_uom: data[i].base_uom,
                quantity: data[i].quantity,
            }
            array.push(str);
        }
        return array;
    }
    
    itemDataObject(data){
        var dataItem = [{
            maintenance_item_usage_id: null,
            maintenance_id: null,
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            base_uom: data.base_uom,
            stock: data.stock,
            quantity: data.quantity,
        }];
        
        return dataItem;
    }
    
    duplicateMaterialChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if ((oldData[i].item_id == newData.item_id)){ 
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }
    
    async addQuery(variables){
        var query = gql`
            mutation ($data:NewMasterMaintenance!) {
                CreateMaintenance (New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
        mutation ($id:Int!, $data:NewMasterMaintenance!) {
            UpdateMaintenance (MaintenanceID:$id, New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    // async deleteQuery(variables){
    //     var query = gql`
    //     mutation ($id:Int!) {
    //         DeleteMachine (MachineID:$id)
    //     }`;
    //     return await globalfunc.defaultApolloMutationDefault(query, variables)
    // }
}

export default new MaintenanceServices();